<template>
  <div>
    <bread-crumb :items="items"></bread-crumb>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="6" sm="8" xs="12">
          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fweb.facebook.com%2FChristoreHub-110555760509184%2F&tabs=timeline&width=900&height=900&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
            width="500"
            height="500"
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
export default {
  name: "EventsAndNews",
  components: { BreadCrumb },
  data() {
    return {
      items: [
        {
          text: "Home",
          disabled: false,
          link: "Home",
        },
        {
          text: "Event and News",
          disabled: true,
        },
      ],
    };
  },
};
</script>
